import labelmake from "labelmake";
import downloadFile from "./Referral-v2.pdf";

export const makePdf = async (data) => {
  const basePdf = await fetch(downloadFile).then((res) => res.arrayBuffer());

  return (async () => {
    const template = {
      basePdf,
      schemas: [
        {
          firstNamePatient: {
            type: "text",
            position: {
              x: 39,
              y: 59.26,
            },
            width: 65.42,
            height: 5.14,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          dobPatient: {
            type: "text",
            position: {
              x: 39,
              y: 65.03,
            },
            width: 65.42,
            height: 5.14,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          mobilePatient: {
            type: "text",
            position: {
              x: 39,
              y: 70.85,
            },
            width: 65.42,
            height: 5.14,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          statePatient: {
            type: "text",
            position: {
              x: 140,
              y: 77.09,
            },
            width: 32.42,
            height: 5.14,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          lastNamePatient: {
            type: "text",
            position: {
              x: 126,
              y: 59.36,
            },
            width: 67.8,
            height: 5.14,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          emailPatient: {
            type: "text",
            position: {
              x: 126,
              y: 70.95,
            },
            width: 68.06,
            height: 5.14,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          postcodePatient: {
            type: "text",
            position: {
              x: 180,
              y: 77.09,
            },
            width: 32.33,
            height: 5.14,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          addressPatient: {
            type: "text",
            position: {
              x: 39,
              y: 77.09,
            },
            width: 155.11,
            height: 5.14,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          guardianFullName: {
            type: "text",
            position: {
              x: 116,
              y: 83,
            },
            width: 105.11,
            height: 5.14,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          sexM: {
            type: "text",
            position: {
              x: 136,
              y: 64.73,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          sexF: {
            type: "text",
            position: {
              x: 150.76,
              y: 64.94,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          sexNon: {
            type: "text",
            position: {
              x: 165,
              y: 64.89,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          crowding: {
            type: "text",
            position: {
              x: 18,
              y: 100.28,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          crossbite: {
            type: "text",
            position: {
              x: 18.21,
              y: 106.58,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          reverseOverjet: {
            type: "text",
            position: {
              x: 18.16,
              y: 112.34,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          mih: {
            type: "text",
            position: {
              x: 18.1,
              y: 118.11,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          preRestorativeConcerns: {
            type: "text",
            position: {
              x: 18.31,
              y: 123.61,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          spacing: {
            type: "text",
            position: {
              x: 75.36,
              y: 100.49,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          deepBite: {
            type: "text",
            position: {
              x: 75.57,
              y: 106.79,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          missingExtraTeeth: {
            type: "text",
            position: {
              x: 75.52,
              y: 112.55,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          periodontalEndodonticConcerns: {
            type: "text",
            position: {
              x: 75.46,
              y: 118.32,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          secondOpinion: {
            type: "text",
            position: {
              x: 75.67,
              y: 123.82,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          openBite: {
            type: "text",
            position: {
              x: 145.16,
              y: 100.17,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          excessiveOverjet: {
            type: "text",
            position: {
              x: 145.37,
              y: 106.47,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          impactedTeeth: {
            type: "text",
            position: {
              x: 145.32,
              y: 112.23,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          invisalignOrClearAligners: {
            type: "text",
            position: {
              x: 145.26,
              y: 118,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          adviceAndNecessaryTreatment: {
            type: "text",
            position: {
              x: 17.78,
              y: 141.86,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          opinion: {
            type: "text",
            position: {
              x: 17.99,
              y: 147.36,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          discussAlternativeTreatmentOptions: {
            type: "text",
            position: {
              x: 100.54,
              y: 141.28,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          other: {
            type: "text",
            position: {
              x: 100.75,
              y: 146.78,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          otherComment: {
            type: "text",
            position: {
              x: 16.4,
              y: 158.55,
            },
            width: 178.14,
            height: 25.78,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1.4,
          },
          otherFollowingRecords: {
            type: "text",
            position: {
              x: 16.61,
              y: 236.55,
            },
            width: 178.14,
            height: 23.93,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1.4,
          },
          drName: {
            type: "text",
            position: {
              x: 37,
              y: 195.46,
            },
            width: 156.96,
            height: 5.14,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          drEmail: {
            type: "text",
            position: {
              x: 37,
              y: 201.49,
            },
            width: 156.96,
            height: 5.14,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          referralDate: {
            type: "text",
            position: {
              x: 37,
              y: 207.52,
            },
            width: 156.96,
            height: 5.14,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          emailedFR: {
            type: "text",
            position: {
              x: 18.46,
              y: 219.01,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          opg: {
            type: "text",
            position: {
              x: 18.41,
              y: 225.04,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          enclosed: {
            type: "text",
            position: {
              x: 77.94,
              y: 218.69,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          lateralCeph: {
            type: "text",
            position: {
              x: 78.15,
              y: 225.25,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          givenToThePatient: {
            type: "text",
            position: {
              x: 128.16,
              y: 218.64,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
          otherFR: {
            type: "text",
            position: {
              x: 128.11,
              y: 225.2,
            },
            width: 5,
            height: 5,
            alignment: "left",
            fontSize: 12,
            fontColor: "#3f3f3f",
            characterSpacing: 0,
            lineHeight: 1,
          },
        },
      ],
    };

    const isChecked = (item) => {
      return item ? "X" : "";
    };

    const isRadioChecked = (value, key) => {
      return value === key ? "X" : "";
    };

    const {
      firstNamePatient,
      dobPatient,
      mobilePatient,
      statePatient,
      lastNamePatient,
      emailPatient,
      postcodePatient,
      addressPatient,
      suburbPatient,
      guardianFullName,
      sex,
      crowding,
      crossbite,
      reverseOverjet,
      mih,
      preRestorativeConcerns,
      spacing,
      deepBite,
      missingExtraTeeth,
      periodontalEndodonticConcerns,
      secondOpinion,
      openBite,
      excessiveOverjet,
      impactedTeeth,
      invisalignOrClearAligners,
      adviceAndNecessaryTreatment,
      opinion,
      discussAlternativeTreatmentOptions,
      other,
      otherComment,
      otherFollowingRecords,
      drName,
      drEmail,
      referralDate,
      followingRecords,
    } = data;
    const inputs = [
      {
        firstNamePatient,
        dobPatient,
        mobilePatient,
        statePatient,
        lastNamePatient,
        emailPatient,
        postcodePatient,
        guardianFullName,
        addressPatient: `${addressPatient},  ${suburbPatient}`,
        sexM: isRadioChecked(sex, "male"),
        sexF: isRadioChecked(sex, "female"),
        sexNon: isRadioChecked(sex, "nonBinary"),
        crowding: isChecked(crowding),
        crossbite: isChecked(crossbite),
        reverseOverjet: isChecked(reverseOverjet),
        mih: isChecked(mih),
        preRestorativeConcerns: isChecked(preRestorativeConcerns),
        spacing: isChecked(spacing),
        deepBite: isChecked(deepBite),
        missingExtraTeeth: isChecked(missingExtraTeeth),
        periodontalEndodonticConcerns: isChecked(periodontalEndodonticConcerns),
        secondOpinion: isChecked(secondOpinion),
        openBite: isChecked(openBite),
        excessiveOverjet: isChecked(excessiveOverjet),
        impactedTeeth: isChecked(impactedTeeth),
        invisalignOrClearAligners: isChecked(invisalignOrClearAligners),
        adviceAndNecessaryTreatment: isChecked(adviceAndNecessaryTreatment),
        opinion: isChecked(opinion),
        discussAlternativeTreatmentOptions: isChecked(
          discussAlternativeTreatmentOptions
        ),
        other: isChecked(other),
        otherComment,
        otherFollowingRecords,
        drName,
        drEmail,
        referralDate,
        emailedFR: isRadioChecked(followingRecords, "emailedFR"),
        opg: isRadioChecked(followingRecords, "opg"),
        enclosed: isRadioChecked(followingRecords, "enclosed"),
        lateralCeph: isRadioChecked(followingRecords, "lateralCeph"),
        givenToThePatient: isRadioChecked(
          followingRecords,
          "givenToThePatient"
        ),
        otherFR: isRadioChecked(followingRecords, "otherFR"),
      },
    ];
    const pdf = await labelmake({ template, inputs });

    // Node.js
    // fs.writeFileSync(path.join(__dirname, 'test.pdf'), pdf);

    // Browser
    const blob = new Blob([pdf.buffer], { type: "application/pdf" });

    return blob;
  })();
};
