export const referral = [
  {
    name: "crowding",
    label: "Crowding",
  },
  {
    name: "spacing",
    label: "Spacing",
  },
  {
    name: "openBite",
    label: "Open bite",
  },
  {
    name: "crossbite",
    label: "Crossbite",
  },
  {
    name: "deepBite",
    label: "Deep bite",
  },
  {
    name: "excessiveOverjet",
    label: "Excessive overjet",
  },
  {
    name: "reverseOverjet",
    label: "Reverse overjet",
  },
  {
    name: "missingExtraTeeth",
    label: "Missing/Extra teeth",
  },
  {
    name: "impactedTeeth",
    label: "Impacted teeth",
  },
  {
    name: "mih",
    label: "MIH",
  },
  {
    name: "periodontalEndodonticConcerns",
    label: "Periodontal/Endodontic concerns",
  },
  {
    name: "invisalignOrClearAligners",
    label: "Invisalign or clear aligners",
  },
  {
    name: "preRestorativeConcerns",
    label: "Pre-restorative concerns",
  },
  {
    name: "secondOpinion",
    label: "Second opinion",
  },
];

export const actions = [
  {
    name: "adviceAndNecessaryTreatment",
    label: "Advice and necessary treatment",
  },
  {
    name: "discussAlternativeTreatmentOptions",
    label: "Discuss alternative treatment options",
  },
  {
    name: "opinion",
    label: "Opinion",
  },
  {
    name: "other",
    label: "Other (specify in comments)",
  },
];
