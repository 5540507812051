import React, { useState, useEffect } from "react";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  Grid,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  CheckboxProps,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
  Collapse,
  Radio,
  RadioProps,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
  OutlindedFieldContainer,
  StyledButton,
  StyledFormGroup,
  StyledTextField,
  StyledGridContainer,
  StyledOtherContainer,
  StyledCheckBoxContainer,
  SectionTitle,
  CheckBoxTitle,
  StyledAlert,
  StyledRadioGroup,
  StyledRadioContainer,
  StyledRadioFormLabel,
  StyledSexRadioGroup,
  Text,
  StyledLink,
} from "./styles";

import { FormTitle } from "../FormTitle";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import { useForm, Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { colors } from "../../styles/variables";
import { getFiles } from "../toBase64File";
import { callEmailAPI } from "../emailSend";
import { actions, referral } from "./data";
import { makePdf } from "./referralPdf";

const GreenCheckbox = withStyles({
  root: {
    color: "#828282",
    "&$checked": {
      color: colors.primary,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const GreenRadio = withStyles({
  root: {
    color: "#828282",
    "&$checked": {
      color: colors.primary,
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    top: {
      color: `${colors.light}`,
      animationDuration: "550ms",
      width: "1rem !important",
      height: "1rem !important",
      margin: "0 0.5rem 0 0",
    },
  })
);
interface Size {
  width: number;
  height: number;
}

const Referral = () => {
  const [size, setSize] = useState<Size>();
  const [gender, setGender] = useState({
    male: "Male",
    female: "Female",
    nonBinary: "Non binary",
  });
  const [isUploadFiles, setIsUploadFiles] = useState(false);
  const [isOtherClinical, setIsOtherClinical] = useState(false);
  const [isOtherFollowingRecords, setIsOtherFollowingRecords] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openSucceed, setOpenSucceed] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openFileSize, setOpenFileSize] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const { register, handleSubmit, errors, control } = useForm();
  const classes = useStyles();

  const mobileProps = {
    maxLength: 10,
  };
  const nameProps = {
    maxLength: 40,
  };
  const addressProps = {
    maxLength: 100,
  };
  const postCodeProps = {
    maxLength: 4,
  };
  const messageProps = {
    maxLength: 350,
  };

  const onFollowingRecordsChange = (e) => {
    e.target.value === "otherFR"
      ? setIsOtherFollowingRecords(true)
      : setIsOtherFollowingRecords(false);
  };

  const onSubmit = async (data) => {
    if (!data.other) {
      data.otherComment = "";
    }
    if (data.followingRecords !== "otherFR") {
      data.otherFollowingRecords = "";
    }

    const referralPdf = await makePdf(data);
    const referralPdfFile = new File(
      [referralPdf],
      `referral-for-${data.firstNamePatient} ${data.lastNamePatient}`,
      {
        type: referralPdf.type,
      }
    );

    setSubmitting(true);

    data.requestPage = "Referral Form";
    let files;
    await getFiles([...uploadFile, referralPdfFile]).then((customJsonFile) => {
      files = customJsonFile;
    });
    data.files = files;
    const maxFileSize = 4194304;
    let totalFileSize = 0;
    files.map((item) => (totalFileSize = totalFileSize + item.fileSize));

    if (totalFileSize < maxFileSize) {
      await callEmailAPI(data).then(({ isSubmitting, response }) => {
        setSubmitting(isSubmitting);
        response.ok ? setOpenSucceed(true) : setOpenError(true);
      });
    } else {
      setOpenFileSize(true);
      setSubmitting(false);
      setUploadFile([]);
    }
    setTimeout(() => {
      window.open(URL.createObjectURL(referralPdf));
    }, 2000);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone();

  const deleteFile = (name) => {
    const index = uploadFile.findIndex((file) => file.name === name);

    uploadFile.splice(index, 1);
    setUploadFile([...uploadFile]);
  };

  const changingUploadFilesCheck = () => {
    setIsUploadFiles(!isUploadFiles);

    if (isUploadFiles) {
      setUploadFile([]);
    }
  };

  const resizeHanlder = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    setSize({
      width,
      height,
    });
    if (width < 420 || (width > 590 && width < 790)) {
      setGender({
        male: "M",
        female: "F",
        nonBinary: "NB",
      });
    } else {
      setGender({
        male: "Male",
        female: "Female",
        nonBinary: "Non binary",
      });
    }
  };

  useEffect(() => {
    setUploadFile([...uploadFile, ...acceptedFiles]);
  }, [acceptedFiles]);

  useEffect(() => {
    window.onresize = resizeHanlder;
    if (!size) {
      resizeHanlder();
    }
  }, []);

  return (
    <>
      <Breadcrumb pageTitle="Online Referral">
        <BreadcrumbItem Link="/referral" Text="Online Referral" />
      </Breadcrumb>
      <PageContainer>
        <FormTitle>Referral Form</FormTitle>
        <Text>
          <strong>Welcome to Gippsland Orthodontics</strong>. We would like to
          thank you for the kind referral. Please do not hesitate to{" "}
          <StyledLink to="/contact">contact</StyledLink> one of our friendly
          team members if there are any questions regarding this form, or if
          additional information is to be submitted. Have a great day from all
          of us at Gippsland Orthodontics.
        </Text>

        <OutlindedFieldContainer>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <StyledGridContainer container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SectionTitle>Patient information</SectionTitle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="First name"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="firstNamePatient"
                  error={errors.firstNamePatient ? true : false}
                />
                <span className="error-message">
                  {errors.firstNamePatient && "First name is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Last name"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="lastNamePatient"
                  error={errors.lastNamePatient ? true : false}
                />
                <span className="error-message">
                  {errors.lastNamePatient && "Last name is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  id="dob"
                  label="Date of birth"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  name="dobPatient"
                  error={errors.dobPatient ? true : false}
                />
                <span className="error-message">
                  {errors.dobPatient && "Date of birth is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledRadioContainer>
                  <StyledRadioFormLabel>Sex</StyledRadioFormLabel>
                  <FormControl component="fieldset">
                    <StyledFormGroup row>
                      <Controller
                        rules={{ required: "Please select one" }}
                        control={control}
                        defaultValue=""
                        name="sex"
                        as={
                          <StyledSexRadioGroup>
                            <FormControlLabel
                              value="male"
                              control={<GreenRadio />}
                              label={gender.male}
                            />
                            <FormControlLabel
                              value="female"
                              control={<GreenRadio />}
                              label={gender.female}
                            />
                            <FormControlLabel
                              value="nonBinary"
                              control={<GreenRadio />}
                              label={gender.nonBinary}
                            />
                          </StyledSexRadioGroup>
                        }
                      />
                    </StyledFormGroup>
                  </FormControl>
                </StyledRadioContainer>
                <span className="error-message">
                  {errors.sex && errors.sex.message}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Phone"
                  variant="outlined"
                  type="text"
                  inputRef={register({
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                    pattern: /[0][0-9]{9}/,
                  })}
                  inputProps={mobileProps}
                  name="mobilePatient"
                  error={errors.mobilePatient ? true : false}
                />
                <span className="error-message">
                  {errors.mobilePatient &&
                    "10 digits mobile number is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Email"
                  variant="outlined"
                  type="text"
                  inputRef={register({
                    required: true,
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  name="emailPatient"
                  error={errors.emailPatient ? true : false}
                />
                <span className="error-message">
                  {errors.emailPatient && "Email address is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={12}>
                <StyledTextField
                  label="Address"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 100 })}
                  inputProps={addressProps}
                  name="addressPatient"
                  error={errors.addressPatient ? true : false}
                />
                <span className="error-message">
                  {errors.addressPatient && "Address is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="City / Suburb"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="suburbPatient"
                  error={errors.suburbPatient ? true : false}
                />
                <span className="error-message">
                  {errors.suburbPatient && "City / Suburb is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={Boolean(errors.statePatient)}
                >
                  <InputLabel>State</InputLabel>
                  <Controller
                    as={
                      <Select>
                        <MenuItem value="" disabled>
                          Please select
                        </MenuItem>
                        <MenuItem value="VIC">VIC</MenuItem>
                        <MenuItem value="NSW">NSW</MenuItem>
                        <MenuItem value="ACT">ACT</MenuItem>
                        <MenuItem value="SA">SA</MenuItem>
                        <MenuItem value="TAS">TAS</MenuItem>
                        <MenuItem value="QLD">QLD</MenuItem>
                        <MenuItem value="WA">WA</MenuItem>
                        <MenuItem value="NT">NT</MenuItem>
                      </Select>
                    }
                    name="statePatient"
                    rules={{ required: "State is required" }}
                    control={control}
                    defaultValue=""
                  />
                  <FormHelperText>
                    {errors.statePatient && errors.statePatient.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <StyledTextField
                  label="Postcode"
                  variant="outlined"
                  type="text"
                  inputRef={register({
                    required: true,
                    minLength: 4,
                    maxLength: 4,
                    pattern: /[0-9]{4}/,
                  })}
                  inputProps={postCodeProps}
                  name="postcodePatient"
                  error={errors.postcodePatient ? true : false}
                />
                <span className="error-message">
                  {errors.postcodePatient && "Postcode is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={12}>
                <StyledTextField
                  label="Parent/Guardian full name if child is under 18 years of age"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: false, maxLength: 100 })}
                  inputProps={addressProps}
                  name="guardianFullName"
                  error={errors.guardianFullName ? true : false}
                />
                <span className="error-message">
                  {errors.guardianFullName && "Address is required"}
                </span>
              </Grid>
            </StyledGridContainer>
            <StyledGridContainer container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SectionTitle>Purpose of referral</SectionTitle>
              </Grid>
              <StyledCheckBoxContainer>
                <StyledFormGroup row>
                  {referral.map(({ name, label }) => (
                    <Grid item xs={12} sm={6} md={4} key={name}>
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            inputRef={register({ required: false })}
                            name={name}
                          />
                        }
                        label={label}
                      />
                    </Grid>
                  ))}
                </StyledFormGroup>
              </StyledCheckBoxContainer>
              {/* <StyledCheckBoxContainer>
                <CheckBoxTitle>
                  <span>Imaging</span>
                </CheckBoxTitle>
                <StyledFormGroup row>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={isUploadFiles}
                        onChange={() => changingUploadFilesCheck()}
                        inputRef={register({ required: false })}
                        name="uploaded"
                      />
                    }
                    label="Uploaded"
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        inputRef={register({ required: false })}
                        name="withPatient"
                      />
                    }
                    label="With patient"
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        inputRef={register({ required: false })}
                        name="emailed"
                      />
                    }
                    label="Emailed"
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        inputRef={register({ required: false })}
                        name="posted"
                      />
                    }
                    label="Posted"
                  />
                </StyledFormGroup>
              </StyledCheckBoxContainer>
              <Grid item xs={12}>
                <div hidden={!isUploadFiles}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FileContainer
                        {...getRootProps({
                          isDragActive,
                          isDragAccept,
                          isDragReject,
                        })}
                      >
                        <FormText>Drop files here or click to upload.</FormText>
                        <input {...getInputProps()} />
                      </FileContainer>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <List dense={false}>
                        {uploadFile.map((file) => (
                          <ListItem key={file.path}>
                            <ListItemText primary={file.name} />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => deleteFile(file.name)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </div>
              </Grid> */}
            </StyledGridContainer>

            <StyledGridContainer container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SectionTitle>Required action</SectionTitle>
              </Grid>
              <StyledCheckBoxContainer>
                <StyledFormGroup row>
                  {actions.map(({ name, label }) =>
                    name === "other" ? (
                      <Grid item xs={12} sm={6} md={4} key={name}>
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={isOtherClinical}
                              onChange={() =>
                                setIsOtherClinical(!isOtherClinical)
                              }
                              inputRef={register({ required: false })}
                              name={name}
                            />
                          }
                          label={label}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6} md={4} key={name}>
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              inputRef={register({ required: false })}
                              name={name}
                            />
                          }
                          label={label}
                        />
                      </Grid>
                    )
                  )}
                </StyledFormGroup>
              </StyledCheckBoxContainer>
              <StyledOtherContainer hidden={!isOtherClinical}>
                <StyledTextField
                  label="Comments:"
                  variant="outlined"
                  multiline
                  minRows={3}
                  type="text"
                  inputRef={register({
                    required: isOtherClinical,
                    maxLength: 350,
                  })}
                  inputProps={messageProps}
                  name="otherComment"
                  error={errors.otherComment ? true : false}
                />
                <span className="error-message">
                  {errors.otherComment && "Please provide your comments"}
                </span>
              </StyledOtherContainer>
            </StyledGridContainer>

            <StyledGridContainer container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SectionTitle>Referring Clinician</SectionTitle>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledTextField
                  label="Referred by"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="drName"
                  error={errors.drName ? true : false}
                />
                <span className="error-message">
                  {errors.drName && "Please provide doctor's name"}
                </span>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledTextField
                  label="Email"
                  variant="outlined"
                  type="text"
                  inputRef={register({
                    required: true,
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  name="drEmail"
                  error={errors.drEmail ? true : false}
                />
                <span className="error-message">
                  {errors.drEmail && "Email is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledTextField
                  id="date"
                  label="Referral date"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  name="referralDate"
                  error={errors.referralDate ? true : false}
                />
                <span className="error-message">
                  {errors.referralDate && "Referral date is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={12}>
                <CheckBoxTitle>
                  <span>The following records have been</span>
                </CheckBoxTitle>
              </Grid>
              <StyledCheckBoxContainer>
                <FormControl
                  component="fieldset"
                  onChange={onFollowingRecordsChange}
                >
                  <StyledFormGroup row>
                    <Controller
                      rules={{ required: "Please select one" }}
                      control={control}
                      defaultValue=""
                      name="followingRecords"
                      as={
                        <StyledRadioGroup>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                              value="emailedFR"
                              control={<GreenRadio />}
                              label="Emailed"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                              value="enclosed"
                              control={<GreenRadio />}
                              label="Enclosed"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                              value="givenToThePatient"
                              control={<GreenRadio />}
                              label="Given to the patient"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                              value="opg"
                              control={<GreenRadio />}
                              label="OPG"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                              value="lateralCeph"
                              control={<GreenRadio />}
                              label="Lateral ceph"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                              value="otherFR"
                              control={<GreenRadio />}
                              label="Other (specify in comments)"
                            />
                          </Grid>
                        </StyledRadioGroup>
                      }
                    />
                  </StyledFormGroup>
                  <span className="error-message">
                    {errors.followingRecords && errors.followingRecords.message}
                  </span>
                </FormControl>
              </StyledCheckBoxContainer>
              <StyledOtherContainer hidden={!isOtherFollowingRecords}>
                <StyledTextField
                  label="Comments:"
                  variant="outlined"
                  multiline
                  minRows={3}
                  type="text"
                  inputRef={register({
                    required: isOtherFollowingRecords,
                    maxLength: 350,
                  })}
                  inputProps={messageProps}
                  name="otherFollowingRecords"
                  error={errors.otherFollowingRecords ? true : false}
                />
                <span className="error-message">
                  {errors.otherFollowingRecords &&
                    "Please provide your comments"}
                </span>
              </StyledOtherContainer>
            </StyledGridContainer>

            <StyledFormGroup row>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    inputRef={register({ required: false })}
                    name="isAppointment"
                  />
                }
                label="Request appointment"
              />
            </StyledFormGroup>

            <Collapse in={openSucceed}>
              <StyledAlert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenSucceed(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Email sent successfully
              </StyledAlert>
            </Collapse>
            <Collapse in={openError}>
              <StyledAlert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Error occurred. Please contact us.
              </StyledAlert>
            </Collapse>
            <Collapse in={openFileSize}>
              <StyledAlert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenFileSize(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Total file size should be less than 4MB. Please send files
                through an email.
              </StyledAlert>
            </Collapse>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? (
                    <CircularProgress className={classes.top} />
                  ) : null}
                  Send
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </OutlindedFieldContainer>
      </PageContainer>
    </>
  );
};

export default Referral;
